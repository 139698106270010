<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="500px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" :rules="rules" label-width="80px">
                <el-form-item label="公共类型" prop="noticeType">
                    <el-select v-model="saveData.noticeType"
                           placeholder="公共类型" clearable size="small" style="width: 240px">
                    <el-option label="系统消息" value="MESSAGE"/>
                    <el-option label="活动通知" value="ACTIVITY"/>
                </el-select>
                </el-form-item>
                <el-form-item label="公共标题" prop="title">
                    <el-input v-model="saveData.title"
                              placeholder="请输入公共标题"/>
                </el-form-item>
                <el-form-item label="通知内容" prop="content">
                    <el-input v-model="saveData.content"  type="textarea"
                              placeholder="请输入通知内容"/>
                </el-form-item>
                <el-form-item label="通知范围" prop="noticeRange">
                    <el-select v-model="saveData.noticeRange" :multiple="multiple" placeholder="通知范围" @change="getChange" clearable size="small" style="width: 240px">
                        <el-option label="全部" value="ALL"/>
                        <el-option label="一级招商中心" value="ORG1"/>
                        <el-option label="二级招商中心" value="ORG2"/>
                        <el-option label="三级招商中心" value="ORG3"/>
                        <el-option label="一级代理商" value="AGENT1"/>
                        <el-option label="二级代理商" value="AGENT2"/>
                        <el-option label="三级代理商" value="AGENT3"/>
                        <el-option label="四级代理商" value="AGENT4"/>
                        <el-option label="五级代理商" value="AGENT5"/>
                        <el-option label="六级代理商" value="AGENT6"/>
                        <el-option label="指定范围" value="RANGE"/>
                    </el-select>
                    <el-row v-if="saveData.noticeRange=='RANGE'">
                        <el-col :span="11">
                            <div><el-radio v-model="radio" label="1">指定编号可见</el-radio></div>
                            <div>
                                <el-input v-model="include"  type="textarea"
                              placeholder="指定编号范围，换行输入编号"/>
                            </div>
                        </el-col>
                        <el-col :span="1" style="width:10px;"> </el-col>
                        <el-col :span="11">
                            <div> <el-radio v-model="radio" label="2">指定编号不可见</el-radio></div>
                            <div>
                                <el-input v-model="exclude"  type="textarea"
                              placeholder="指定编号范围，换行输入编号"/>
                            </div>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="生效时间" prop="effectiveDate">
                    <el-date-picker
                    v-model="saveData.effectiveDate"
                    type="date" value-format="yyyy-MM-dd"
                    placeholder="请输入生效日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="通知时间" prop="noticeDate">
                    <el-date-picker
                    v-model="saveData.noticeDate"
                    type="date" value-format="yyyy-MM-dd"
                    placeholder="请输入生效日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">提交</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {UserApi} from '@/api';
    export default {
        name: "CustomForm",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            isAdd: {
                type: Boolean,
                default: true,
            },
            selectRow: {
                type: Object,
                default: function () {
                    return {}
                },
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
                var checkRange = (rule, value, callback) => {
                    if (!value) {
                        return callback(new Error('请选择通知范围'));
                    } else{
                        if(value == 'RANGE'){
                            if(this.radio == '1'){
                                if(!this.include){
                                    return callback(new Error('指定范围可见不能为空'));
                                }
                            } else{
                                if(!this.exclude){
                                    return callback(new Error('指定范围不可见不能为空'));
                                }
                            }
                        }
                        callback();
                    }
            };
            return {
                title: '',
                saveData: {},
                open: false,
                multiple: true,
                include:'',
                exclude:'',
                radio: '1',
                rules:{
                    noticeType:[{ required: true, message: '请选择公告类型', trigger: 'change' }],
                    noticeRange:[{ validator: checkRange, trigger: 'change' }],
                    title:[{ required: true, message: '请输入公告标题', trigger: 'blur' }],
                    content:[{ required: true, message: '请输入公告内容', trigger: 'blur' }],
                    effectiveDate:[{ required: true, message: '请选择生效日期', trigger: 'change' }],
                    noticeDate:[{ required: true, message: '请选择通知日期', trigger: 'change' }],
                }
            }
        },
        watch: {
            onFresh: function (value) {
                this.open = value;
                if (value) {
                    this.resetData();
                }
            }
        },
        mounted() {
        },
        methods: {
            submitCheck(){
                this.$refs.saveOrEditForm.validate((valid) => {
                    if (valid) {
                       this.submitForm()
                    } else {
                        return false;
                    }
                });
            },
            async resetData() {
                if (this.isAdd){
                    this.title = "添加公告";
                    this.saveData = {};
                }else {
                    
                    let result = await UserApi.noticeInfo.detail(this.selectRow.noticeNo)
                    this.isAdd = false;
                    this.title = "修改公告";
                    if(!result.success){
                        return
                    }
                    this.saveData = result.data.noticeInfoDo;
                    if(this.saveData.noticeRange=='RANGE'){
                        if(result.data.include.length>0){
                            this.radio = '1'
                            this.include = result.data.include.join('\n')
                        } else if(result.data.exclude.length>0){
                            this.radio = '2'
                            this.exclude = result.data.exclude.join('\n')
                        }
                    }
                    this.saveData.noticeRange = this.saveData.noticeRange.split(',')
                    
                    
                }
            },
            async submitForm() {
                console.log(this.exclude)
                console.log(this.include)
                let params = {
                    exclude: this.radio == '2' ? (this.exclude ? this.exclude.split('\n') : []) : [],
                    include: this.radio == '1' ? (this.include ? this.include.split('\n') : []) : [],
                    noticeInfoDo: this.saveData
                }
                params.noticeInfoDo.noticeRange = this.saveData.noticeRange.length>1 ? this.saveData.noticeRange.join(',') : this.saveData.noticeRange.toString()
                console.log(params)
                let result = (this.isAdd) ?
                        (await UserApi.noticeInfo.save(params)) :
                        (await UserApi.noticeInfo.edit(this.saveData.noticeNo, params));
                if (result.success) {
                    this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                    this.$refs.saveOrEditForm.resetFields();
                    this.commitOnClose();
                } else{
                    params.noticeInfoDo.noticeRange = this.saveData.noticeRange.split(',')
                }
            },
            cancel() {
                this.saveData = {};
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            },
            getChange(val){
                if(val.length==0){
                    return
                }
                let regu = new RegExp(/ALL|RANGE|((ORG[1-3]|AGENT[1-6])(,(ORG[1-3]|AGENT[1-6])){0,8})/)
                let matchData = val.join(',')
                console.log((val.join(',')).match(regu))
                this.saveData.noticeRange = matchData.match(regu)[0].indexOf(',')>-1?matchData.match(regu)[0].split(',') : new Array(matchData.match(regu)[0])
            },
        }
    };
</script>

<style scoped>

</style>
